import React, {useState, useEffect} from 'react';
import {useParams, useLocation, Link} from 'react-router-dom';
import '../../Styles/Games.css'
import Spinner from 'react-bootstrap/Spinner';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';

function Games() {

  var [data, setData] = useState([])
  var [loaded, setLoaded] = useState(false)
  var [showingGW, setShowingGW] = useState([])
  var [selectedGameweek, setSelectedGameweek] = useState(null);
  const [selectedLeague, setSelectedLeague] = useState(null);

  const resetFilter = () => {
    setSelectedLeague(null);
  };

  const groupedGames = {};

    function formatGameDateTime(inputDateTime) {
    // Create a Date object using the input string
    const originalDateTime = new Date(inputDateTime);
  
    // Extract day, month, year, hours, and minutes from the Date object
    const day = originalDateTime.getDate().toString().padStart(2, '0');
    const month = (originalDateTime.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = originalDateTime.getFullYear();
    const hours = originalDateTime.getHours().toString().padStart(2, '0');
    const minutes = originalDateTime.getMinutes().toString().padStart(2, '0');
  
    // Create the formatted string
    const formattedDateTime = `${day}.${month}.${year} ${hours}:${minutes}`;
  
    return formattedDateTime;
  }

  const formatDate = (date) => {
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const yyyy = date.getFullYear();
    return `${dd}.${mm}.${yyyy}`;
  };

  function convertDateFormat(inputDate) {
    const [day, month, year] = inputDate.split('.');
    const convertedDate = `${year}-${month}-${day}`;
    return convertedDate;
  }

  function getGameweeks(pastWeeks, futureWeeks) {
    const result = [];
  
    for (let i = -pastWeeks; i <= futureWeeks; i++) {
      const offset = i * 7;
      
      const startOfWeek = new Date();
      startOfWeek.setDate(startOfWeek.getDate() + offset);
  
      const daysUntilLastTuesday = (startOfWeek.getDay() + 5) % 7;
      startOfWeek.setDate(startOfWeek.getDate() - daysUntilLastTuesday);
  
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6);
  
      result.push({
        start: formatDate(startOfWeek),
        end: formatDate(endOfWeek)
      });
    }

    return result;
  }

  const GW = getGameweeks(1, 1);
  
  useEffect(() => {
    if (selectedGameweek || selectedGameweek === null) {
      // Fetch games for the selected gameweek
      const { start, end } = selectedGameweek || GW[Math.floor(GW.length / 2)];
      console.log(start)
      console.log(end)
      console.log(convertDateFormat(end))
      fetch(`https://stocksfcperformance.com/public/get/live-games/${convertDateFormat(start)}/${convertDateFormat(end)}`)
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          setData(data.games);
          setLoaded(true);
        });
    }
  }, [selectedGameweek]);

  // Group games by league
  data.forEach((game) => {
    const leagueName = game.leaguename;
    if (!groupedGames[leagueName]) {
      groupedGames[leagueName] = [];
    }
    groupedGames[leagueName].push(game);
  });

  const handleTabSelect = (selectedKey) => {
    // Update the selectedGameweek based on the selected tab
    const index = parseInt(selectedKey, 10); // Assuming the tab keys are numeric indices
    setSelectedGameweek(GW[index]);
  };

  const renderComps = () => {
    return data.map(game =>
      <div className="col-sm-12 mb-2">
        <div className="card round_corners">       
          <div className="card-body" style={{ flex: 1 }}>
            <div><h5>{game.leaguename}</h5> <img className='compflag' src={game.leagueLogo} alt='club-logo'/>   </div>
            <img className='compflag' src={game.homeTeamLogo} alt='club-logo'/> <img className='compflag' src={game.awayTeamLogo} alt='club-logo'/>            
            <p className="card-text">{game.homeTeamName} - {game.awayTeamName}</p>      
            <p className="card-text">{formatGameDateTime(game.date)}</p>      
            {/* <p className="card-text">{game.homeGoals} - {game.awayGoals}</p>  */}     
          </div>          
        </div>
      </div>
    )      
  }

  const filteredGames = selectedLeague ? groupedGames[selectedLeague] : data;

  const renderCompGames = () => {
    return (
      <Row className="justify-content-center">
        {Object.entries(groupedGames).map(([leagueName, leagueGames]) => (
          <Col className='game_table' xs={12} md={10} lg={9} xl={8}>
            {(!selectedLeague || selectedLeague === leagueName) && (
            <Card>
              <Card.Header><img className='league_logo' src={leagueGames[0].leagueLogo} alt='league-logo'/>{leagueName}</Card.Header>
              <Card.Body>
                {leagueGames.map((game) => (
                   <div className="col-sm-12 mb-2">
                   <div className="card round_corners">       
                     <div className="card-body" style={{ flex: 1 }}>
                       <img className='club_logo' src={game.homeTeamLogo} alt='club-logo'/> <img className='club_logo' src={game.awayTeamLogo} alt='club-logo'/>            
                       <p className="card-text">{game.homeTeamName} - {game.awayTeamName}</p>      
                       <p className="card-text">{formatGameDateTime(game.date)}</p>      
                       {/* <p className="card-text">{game.homeGoals} - {game.awayGoals}</p>  */}     
                     </div>          
                   </div>
                 </div>
                ))}
              </Card.Body>
            </Card> )}
          </Col>
        ))}
      </Row>
    );  
  }

  return (
    <div className="mainbody">
      <p className="headline_comp bold">Live games</p>
      <Tabs
        defaultActiveKey="1"
        onSelect={handleTabSelect}
        id="fill-tab"
        className="mb-3"
        fill
      >
        {GW.map((gameweek, index) => (
          <Tab key={index} eventKey={index.toString()} title={index === 0 ? "Last Gameweek" : index === Math.floor(GW.length / 2) ? "Present Gameweek" : index === GW.length - 1 ? "Next Gameweek" : `Gameweek ${index + 1}`}>
            <p className="headline_comp bold">{`${gameweek.start} - ${gameweek.end}`}</p>
          </Tab>
        ))}
        
      </Tabs>
      <div className="mb-3 d-flex justify-content-center">
      <DropdownButton      
        id="dropdown-leagues"
        title={selectedLeague ? selectedLeague : 'Select League'}
      >
        <Dropdown.Item key="All" onClick={resetFilter}>
          All
        </Dropdown.Item>
        {Object.keys(groupedGames).map((leagueName) => (
          <Dropdown.Item
            key={leagueName}
            onClick={() => {
              console.log('Selected League:', leagueName);
              setSelectedLeague(leagueName)}
            }
          >
            {leagueName}
          </Dropdown.Item>
        ))}
      </DropdownButton>
      </div>
      <div className="row justify-content-center frame_gap">
        {loaded ? (
          renderCompGames()
        ) : (
          <Spinner className="spinner-centered" animation="border" size="xxl" />
        )}
      </div>     
    </div>
  );

}

export default Games;