import React from 'react';
import '../Styles/Welcome.css';
import { MdHeight } from 'react-icons/md';

function Home() {

  return (
    /*<div className='mainbody'>
      <div className='mainbody-text'>    
        <h1>Welcome to StocksFC Performance!</h1>      
        <h2>StocksFC Performance is the ultimate tool for StocksFC investors!</h2>
          
        <div className='container_features'>
          <br></br>
          <h2>Here are just a few reasons why our platform is a must-have for any serious investor:</h2>
          <br></br>
          <ul className='features'>
            <li className='features_individual'>Check performance of players</li>
            <li className='features_individual'>Plan your next games with the game schedule</li>
            <li className='features_individual'>See all available players on the platform and their current team</li>
            <li className='features_individual'></li>
            <li className='features_individual'></li>            
          </ul>
          <br></br>
          </div>
      </div>
    </div>*/
    
  	<div className='mainbody'>
      <div style={{ backgroundImage : `url(https://noawag.de/assets/welcome_bg.png)`, height: '40vh'}} >
        <h1 className='welcome-title'>Welcome to StocksFC Performance!</h1>
          
        <p className='welcome-sub-title'>This is your go-to platform for comprehensive information about players, clubs, performances, games and more.</p>
        <p className='welcome-sub-title'>Our goal is to help StocksFC players stay informed and make the best decisions.</p>
      </div>

      <div class="container-divided">
        <div class="left-content">
          <h2>Check out teams and players</h2>
          <section className="features">
            <ul>
              <li className='left-content-text'>Competitions and clubs overview</li>
              <li className='left-content-text'>Player overview for issued players</li>
              <li className='left-content-text'>General and performance information for players</li>
            </ul>
          </section>
        </div>
        <div class="right-content">
        <img className='right-content-image' src={"https://noawag.de/assets/salah_overview.png"} alt='player-img' />      
        </div>
      </div>     

      <div class="container-divided">
        <div class="left-content">
          <h2>Plan your strategy</h2>
          <section className="features">
            <ul>
              <li className='left-content-text'>Game schedule to plan your next buys</li>
              <li className='left-content-text'>See upcoming games for teams and individual players</li>
            </ul>
          </section>
        </div>
        <div class="right-content">
        <img className='right-content-image' src={"https://noawag.de/assets/games_overview.png"} alt='player-img' />      
        </div>
      </div>       
    </div>
    
  );
  

}

export default Home;